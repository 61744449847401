import App from 'App';
import MonitorTreasuriesContainer from 'containers/MonitorTreasuriesContainer';
import React from 'react';

const Farm: React.FC<{}> = () => {
  return (
    <App>
      <MonitorTreasuriesContainer onlyNeedAttention={false} />
    </App>
  );
};

export default Farm;
