/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable operator-linebreak */
import FormatFloat from 'common/components/FormatPercent/FormatFloat';
import { MonitorTreasuries, StablePools } from 'common/models/monitorVaults';
import { StyledFlexColumn, StyledTableBodyTr, StyledTdSub, StyledTdItem } from 'common/styles/common.styles';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import React from 'react';
import { StyledMonitorVaultsSubTextOne } from './monitorTreasuriesTable.style';

type Props = {
  treasury: MonitorTreasuries;
  isShown: boolean;
};

const MonitorTreasuriesTablePositionDetails: React.FC<Props> = (props) => {
  if (props.isShown) {
    return (
      <>
        {/* ---------------------- */}
        {/* ROW 1: usdcOnHand & uninvestedStimulus */}
        {/* ---------------------- */}
        <StyledTableBodyTr key={`${props.treasury.name}1`} className="table-no-border-bottom">
          <StyledTdSub colSpan={1}>
            <StyledTdItem className="primary-text-color">
              <StyledMonitorVaultsSubTextOne className="sub-text-color">
                <span>usdcOnHand</span>
              </StyledMonitorVaultsSubTextOne>
            </StyledTdItem>
          </StyledTdSub>
          <StyledTdSub colSpan={1}>
            <StyledTdItem className="primary-text-color">
              <StyledMonitorVaultsSubTextOne className="sub-text-color">
                <FormatFloat value={props.treasury.usdcOnHand} prefix="$" />
              </StyledMonitorVaultsSubTextOne>
            </StyledTdItem>
          </StyledTdSub>
          <StyledTdSub colSpan={1}>
            <StyledTdItem className="primary-text-color">
              <StyledMonitorVaultsSubTextOne className="sub-text-color">
                <span>uninvestedStimulus</span>
              </StyledMonitorVaultsSubTextOne>
            </StyledTdItem>
          </StyledTdSub>
          <StyledTdSub colSpan={3}>
            <StyledTdItem className="primary-text-color">
              <StyledMonitorVaultsSubTextOne className="sub-text-color">
                <FormatFloat value={props.treasury.uninvestedStimulus} prefix="$" />
              </StyledMonitorVaultsSubTextOne>
            </StyledTdItem>
          </StyledTdSub>
        </StyledTableBodyTr>
        {/* ---------------------- */}
        {/* ROW 2: mintedBacked & address */}
        {/* ---------------------- */}
        <StyledTableBodyTr key={`${props.treasury.name}2`} className="table-no-border-bottom">
          <StyledTdSub colSpan={1}>
            <StyledTdItem className="primary-text-color">
              <StyledMonitorVaultsSubTextOne className="sub-text-color">
                <span>mintedBacked</span>
              </StyledMonitorVaultsSubTextOne>
            </StyledTdItem>
          </StyledTdSub>
          <StyledTdSub colSpan={1}>
            <StyledTdItem className="primary-text-color">
              <StyledMonitorVaultsSubTextOne className="sub-text-color">
                <FormatFloat value={props.treasury.mintedBacked} prefix="$" />
              </StyledMonitorVaultsSubTextOne>
            </StyledTdItem>
          </StyledTdSub>
          <StyledTdSub colSpan={1}>
            <StyledTdItem className="primary-text-color pb-3">
              <StyledFlexColumn>
                <StyledMonitorVaultsSubTextOne className="sub-text-color">
                  <span>address</span>
                </StyledMonitorVaultsSubTextOne>
              </StyledFlexColumn>
            </StyledTdItem>
          </StyledTdSub>
          <StyledTdSub colSpan={3}>
            <StyledTdItem className="primary-text-color">
              <StyledMonitorVaultsSubTextOne className="sub-text-color">
                <OutboundLink
                  href={`https://etherscan.io/token/${props.treasury.address}`}
                  className="sub-text-color"
                  target="blank"
                >{props.treasury.address}
                </OutboundLink>
              </StyledMonitorVaultsSubTextOne>
            </StyledTdItem>
          </StyledTdSub>
        </StyledTableBodyTr>
        {/* ---------------------- */}
        {/* ROW 3: inCirculation & strategy */}
        {/* ---------------------- */}
        <StyledTableBodyTr key={`${props.treasury.name}3`} className="table-no-border-bottom">
          <StyledTdSub colSpan={1}>
            <StyledTdItem className="primary-text-color">
              <StyledMonitorVaultsSubTextOne className="sub-text-color">
                <span>inCirculation</span>
              </StyledMonitorVaultsSubTextOne>
            </StyledTdItem>
          </StyledTdSub>
          <StyledTdSub colSpan={1}>
            <StyledTdItem className="primary-text-color">
              <StyledMonitorVaultsSubTextOne className="sub-text-color">
                <FormatFloat value={props.treasury.inCirculation} prefix="$" />
              </StyledMonitorVaultsSubTextOne>
            </StyledTdItem>
          </StyledTdSub>
          <StyledTdSub colSpan={1}>
            <StyledTdItem className="primary-text-color pb-3">
              <StyledFlexColumn>
                <StyledMonitorVaultsSubTextOne className="sub-text-color">
                  <span>strategy</span>
                </StyledMonitorVaultsSubTextOne>
              </StyledFlexColumn>
            </StyledTdItem>
          </StyledTdSub>
          <StyledTdSub colSpan={3}>
            <StyledTdItem className="primary-text-color">
              <StyledMonitorVaultsSubTextOne className="sub-text-color">
                <OutboundLink
                  href={`https://etherscan.io/address/${props.treasury.strategy}`}
                  className="sub-text-color"
                  target="blank"
                >{props.treasury.strategy}
                </OutboundLink>
              </StyledMonitorVaultsSubTextOne>
            </StyledTdItem>
          </StyledTdSub>
        </StyledTableBodyTr>
        {/* ---------------------- */}
        {/* ROW 4: uninvestedCollateral */}
        {/* ---------------------- */}
        <StyledTableBodyTr key={`${props.treasury.name}4`} className="table-no-border-bottom">
          <StyledTdSub colSpan={1}>
            <StyledTdItem className="primary-text-color">
              <StyledMonitorVaultsSubTextOne className="sub-text-color">
                <span>uninvestedCollateral</span>
              </StyledMonitorVaultsSubTextOne>
            </StyledTdItem>
          </StyledTdSub>
          <StyledTdSub colSpan={5}>
            <StyledTdItem className="primary-text-color">
              <StyledMonitorVaultsSubTextOne className="sub-text-color">
                <FormatFloat value={props.treasury.uninvestedCollateral} prefix="$" />
              </StyledMonitorVaultsSubTextOne>
            </StyledTdItem>
          </StyledTdSub>
        </StyledTableBodyTr>
        <StyledTableBodyTr key={`${props.treasury.name}5`} className="table-border-bottom"><td /></StyledTableBodyTr>
      </>
    );
  // eslint-disable-next-line no-else-return
  } else {
    return (<></>);
  }
};

export default MonitorTreasuriesTablePositionDetails;
