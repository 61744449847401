import { gql } from '@apollo/client';

export const listMonitorTreasuriesQuery = gql`
  query ListMonitorTreasuries {
    listMonitorTreasuries {
      items {
        address
        alerts {
          name
          value
        }
        backedRatio
        displayName
        inCirculation
        mintedBacked
        name
        needAttention
        numAlerts
        numWarnings
        reserveRatio
        strategy
        uninvestedCollateral
        uninvestedStimulus
        usdcOnHand
              warnings {
          name
          value
        }
      }
    }
  }
`;
