import { useQuery } from '@apollo/client';
import SpinnerContainer from 'common/components/SpinnerContainer';
import { QueryItemsResponse, QueryName } from 'common/models/graphql';
import { IchiJson } from 'common/models/ichiJson';
import { REFRESH_INTERVALS } from 'constants/constants';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { MonitorTreasuries } from 'common/models/monitorVaults';
import { listMonitorTreasuriesQuery } from 'graphql/queries/listMonitorTreasuries';
import MonitorTreasuriesTable from 'common/components/MonitorTreasuriesTable/MonitorTreasuriesTable';
import { ContentTableWrapper } from './app.style';

type Props = {
  onlyNeedAttention: boolean;
};

const MonitorTreasuriesContainer: React.FC<Props> = (props) => {
  const { loading, error, data } = useQuery<QueryItemsResponse<MonitorTreasuries>>(listMonitorTreasuriesQuery, {
    fetchPolicy: 'cache-and-network',
    pollInterval: REFRESH_INTERVALS[QueryName.listMonitorTreasuries]
  });

  const StaticData: IchiJson = useStaticQuery(graphql`
    query {
      ichiJson {
        TREASURIES_TABLE {
          headers {
            name
            helpText
          }
        }
      }
    }
  `);

  const treasuries: MonitorTreasuries[] = props.onlyNeedAttention
    ? data?.listMonitorTreasuries?.items.filter((t) => t.needAttention) || []
    : data?.listMonitorTreasuries?.items || [];

  const table = StaticData.ichiJson.TREASURIES_TABLE;

  if (error) {
    console.error(error);
  }

  return (
    <SpinnerContainer isLoading={loading}>
      <ContentTableWrapper>
        <MonitorTreasuriesTable table={table} treasuries={treasuries} />
      </ContentTableWrapper>
    </SpinnerContainer>
  );
};

export default MonitorTreasuriesContainer;
