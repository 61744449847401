/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable operator-linebreak */
import { MonitorTreasuries, StablePools } from 'common/models/monitorVaults';
import { StyledTableBodyTr, StyledTd, StyledTdItem } from 'common/styles/common.styles';
import React from 'react';
import FormatBoolean from '../FormatBoolean/FormatBoolean';
import FormatNumber from '../FormatPercent/FormatNumber';
import { StyledMonitorVaultsCoinName, StyledMonitorVaultsSubTextOne } from './monitorTreasuriesTable.style';
import MonitorTreasuryTablePositionDetails from './MonitorTreasuryTablePositionDetails';

type Props = {
  treasury: MonitorTreasuries;
};

const MonitorTreasuriesTablePosition: React.FC<Props> = (props) => {
  const [isTreasurySelected, setIsTreasurySelected] = React.useState(false);
  const toggleSelected = () => {
    setIsTreasurySelected(!isTreasurySelected);
  };

  return (
    <>
      <StyledTableBodyTr
        className={isTreasurySelected ? 'no-border-bottom  cursor-pointer' : 'table-border-bottom cursor-pointer'}
        key={props.treasury.name}
        onClick={toggleSelected}
      >
        {/* ---------------------- */}
        {/* Name */}
        {/* ---------------------- */}
        <StyledTd>
          <StyledTdItem className="primary-text-color">
            <StyledMonitorVaultsCoinName className="primary-text-color">
              <span>{props.treasury.displayName}</span>
            </StyledMonitorVaultsCoinName>
          </StyledTdItem>
        </StyledTd>
        {/* ---------------------- */}
        {/* Needs Attention */}
        {/* ---------------------- */}
        <StyledTd>
          <StyledTdItem className="primary-text-color pb-3">
            <FormatBoolean value={props.treasury.needAttention} />
          </StyledTdItem>
        </StyledTd>
        {/* ---------------------- */}
        {/* Alerts */}
        {/* ---------------------- */}
        <StyledTd>
          <StyledTdItem className="primary-text-color pb-3">
            <ul>
              {props.treasury.alerts?.map((item) => (
                <li key={item.name} className="pb-3">
                  <StyledMonitorVaultsSubTextOne key={item.name} className="primary-text-color">
                    {item.name}: {item.value}%
                  </StyledMonitorVaultsSubTextOne>
                </li>
              ))}
            </ul>
          </StyledTdItem>
        </StyledTd>
        {/* ---------------------- */}
        {/* Warnings */}
        {/* ---------------------- */}
        <StyledTd>
          <StyledTdItem className="primary-text-color pb-3">
            <ul>
              {props.treasury.warnings?.map((item) => (
                <li key={item.name} className="pb-3">
                  <StyledMonitorVaultsSubTextOne key={item.name} className="primary-text-color">
                    {item.name}: {item.value}%
                  </StyledMonitorVaultsSubTextOne>
                </li>
              ))}
            </ul>
          </StyledTdItem>
        </StyledTd>
        {/* ---------------------- */}
        {/* backedRatio */}
        {/* ---------------------- */}
        <StyledTd>
          <StyledTdItem className="primary-text-color pb-3">
            <FormatNumber value={props.treasury.backedRatio} displayDecimals={1} postfix="%" />
          </StyledTdItem>
        </StyledTd>
        {/* ---------------------- */}
        {/* reserveRatio */}
        {/* ---------------------- */}
        <StyledTd>
          <StyledTdItem className="primary-text-color pb-3">
            <FormatNumber value={props.treasury.reserveRatio} displayDecimals={1} postfix="%" />
          </StyledTdItem>
        </StyledTd>
      </StyledTableBodyTr>
      <MonitorTreasuryTablePositionDetails
        isShown={isTreasurySelected}
        treasury={props.treasury}
      />
    </>
  );
};

export default MonitorTreasuriesTablePosition;
